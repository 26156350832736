<template>
  <div class="sideButton">
    <div class="sideItem">
      <img src="@/assets/image/sidebutton/4.png" alt="" />
      咨询电话
      <div class="phoneOrder">联系电话：18820865235</div>
    </div>
    <div class="sideItem">
      <img src="@/assets/image/sidebutton/3.png" alt="" />
      微信公众号
      <div class="codeOrder">
        <img src="@/assets/image/guanzhu.png" alt="" srcset="" />
        <p>关注公众号立即预约家政服务</p>
      </div>
    </div>
    <div class="sideItem">
      <img src="@/assets/image/sidebutton/2.png" alt="" />
      资讯管理
    </div>
    <div class="sideItem">
      <img src="@/assets/image/sidebutton/1.png" alt="" />
      <a href="javascript:;" @click="goTop">返回顶部</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "siderBox",
  props: {},
  methods: {
    // 返回顶部
    goTop() {
      document.querySelector("#top").scrollIntoView(true);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* 侧边按钮 */
.sideButton {
  position: fixed;
  top: 13.75vw;
  right: 0px;
  width: 4.5833vw;
  height: 16.7708vw;
  display: flex;
  flex-direction: column;
  .sideItem {
    color: #fff;
    width: 4.5833vw;
    height: 3.6979vw;
    margin: 0.0521vw;
    background-color: #4dadf7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 0.7292vw;
    a {
      color: #fff;
    }
    > img {
      width: 0.9375vw;
      height: 0.9375vw;
      margin-bottom: 3px;
    }
  }
  .sideItem:hover {
    .phoneOrder {
      display: block;
    }
    .codeOrder {
      display: block;
    }
  }
}
/* 联系电话hover效果 */
.phoneOrder {
  display: none;
  line-height: 1.5528vw;
  padding: 0px 0.5176vw;
  position: absolute;
  border-radius: 0.2588vw;
  left: -9.8344vw;
  height: 1.5528vw;
  background-color: #4dadf7;
}
.phoneOrder::after {
  content: "";
  width: 0px;
  height: 0px;
  border-top: 0.5176vw solid transparent;
  border-bottom: 0.5176vw solid transparent;
  border-left: 0.5176vw solid #4dadf7;
  position: absolute;
  top: 0.2588vw;
  left: 9.058vw;
}

/* 扫码下单 */
.codeOrder {
  border-radius: 0.2588vw;
  display: none;
  padding: 0.5176vw;
  background-color: #4dadf7;
  top: 4.1408vw;
  left: -9.3168vw;
  position: absolute;
  width: 8.7992vw;
  height: 9.6273vw;
  ::before {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 0.5176vw solid transparent;
    border-bottom: 0.5176vw solid transparent;
    border-left: 0.5176vw solid #4dadf7;
    position: absolute;
    top: 1.0352vw;
    left: 8.7992vw;
  }
  > img {
    width: 7.3499vw;
    height: 7.3499vw;
  }
  > p {
    margin-top: 0.5176vw;
    line-height: 0.6211vw;
    font-weight: 600;
    width: 8.2816vw;
    height: 0.7246vw;
    font-size: 0.6211vw;
    color: #fbfbfb;
  }
}
</style>
