import { createApp } from "vue";
// 淘宝js布局  已经弃用  保留于此看后期是否做移动端兼容
// import "../node_modules/amfe-flexible/index";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import siderBox from "@/components/sider.vue";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);
// 侧边栏，注册为全局组件，方柏霓后续有重复的地方直接调用
app.component("siderBox", siderBox);

app.use(ElementPlus).use(router).mount("#app");
