import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/officialWebsite",
    name: "officialWebsite",
    component: () => import("@/views/wholeView.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
      },
      {
        path: "/productCenter",
        name: "productCenter",
        component: () => import("@/views/productCenter.vue"),
      },
      {
        path: "/newsInformation",
        // 暂时隐藏 重定向到首页
        redirect: "/home",
        name: "newsInformation",
        component: () => import("@/views/newsInformation.vue"),
      },
      {
        path: "/settleIn",
        name: "settle",
        component: () => import("@/views/settleIn.vue"),
      },
      {
        path: "/aboutHome",
        // 暂时重定向到首页
        redirect: "/home",
        // redirect: "/aboutHome/about",
        name: "aboutView",
        component: () => import("@/views/about/AboutView.vue"),
        children: [
          {
            path: "/aboutHome/about",
            // 暂时重定向到首页
            redirect: "/home",
            name: "about",
            component: () => import("@/views/about/about/about.vue"),
          },
          {
            path: "/aboutHome/join",
            // 暂时重定向到首页
            redirect: "/home",
            name: "join",
            component: () => import("@/views/about/join/join.vue"),
          },
          {
            path: "/aboutHome/contact",
            // 暂时重定向到首页
            redirect: "/home",
            name: "contact",
            component: () => import("@/views/about/contact/contact.vue"),
          },
        ],
      },
    ],
  },
  // {  // 因为和vue2使用的东西不同，因此这个登录页暂时不投入使用
  //   path: "/login",
  //   name: "login",
  //   component: () => import("../views/login.vue"),
  // },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
