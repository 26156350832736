<template>
  <router-view></router-view>
</template>

<script setup></script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  text-decoration: none;
}

/* 中间部分banner图样式 在此处设为全局样式 */
.container_image {
  display: block;
  width: 100vw;
  height: 23.9583vw;
}

/* 每个中间展示区块的样式，提取为公共样式使用 */
.homePage {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* 每个区块的头部 */
  .pageHead {
    margin-top: 50px;
    width: 62.7083vw;
    height: 2.6042vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header_image {
      width: 27.2396vw;
      height: 4.9479vw;
    }
    .header_text {
      font-size: 0.8333vw;
      width: 27.2396vw;
      height: 2.5vw;
      text-align: left;
      color: #666666;
      font-family: SourceHanSansSC-regular;
    }
  }
  /* 宫廷文件展示图 */
  .pageBanner {
    width: 62.7083vw;
    height: 35.5208vw;
    margin-top: 50px;
  }
  .advantage {
    width: 62.7083vw;
    height: auto;
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 19.5833vw);
    grid-template-rows: repeat(2, 14.5313vw);
    gap: 1.8229vw 1.8229vw;
    > img {
      width: 19.5833vw;
      height: 14.4792vw;
    }
    .advabtage_item {
      width: 19.5833vw;
      height: 22.1875vw;
      img {
        width: 19.5833vw;
        height: 11.0417vw;
      }
      > div {
        position: relative;
        width: 19.5833vw;
        height: 10.9375vw;
        padding-top: 1.0417vw;
        padding-left: 1.5625vw;
        p {
          width: 9.375vw;
          height: 2.3438vw;
          font-size: 1.5625vw;
          font-weight: 800;
          color: rgba(255, 255, 255, 1);
          text-align: left;
          font-family: SourceHanSansSC-regular;
        }
        span {
          margin-top: 0.3646vw;
          font-size: 0.9375vw;
          width: 13.0208vw;
          height: 2.8125vw;
          display: block;
          color: rgba(255, 255, 255, 1);
          text-align: left;
          font-family: SourceHanSansSC-regular;
        }
        /* 详情按钮 */
        .ad_button {
          display: block;
          border-radius: 50%;
          position: relative;
          top: 0vw;
          left: 13.5417vw;
          width: 3.4375vw;
          height: 3.4375vw;
          line-height: 3.4375vw;
          color: rgba(64, 168, 247, 1);
          background-color: rgba(255, 255, 255, 1);
          font-size: 0.7292vw;
          text-align: center;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
          font-family: Roboto;
        }
      }
    }
  }
  .advantage2 {
    margin-bottom: 2.6042vw;
    grid-template-columns: repeat(3, 19.5833vw);
    grid-template-rows: repeat(2, 22.1875vw);
  }
}
</style>
